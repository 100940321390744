<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For each of the substances listed below, determine whether they would exist as a solid,
        liquid, or gas under ordinary temperature and pressure.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Substance</th>
            <th style="font-size: 15px">Solid</th>
            <th style="font-size: 15px">Liquid</th>
            <th style="font-size: 15px">Gas</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><stemble-latex content="$\text{Oxygen}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Sodium Chloride (table salt)}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{Mercury}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{Carbon Dioxide}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{Helium}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td><stemble-latex content="$\text{Copper}$" /></td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Isopropyl alcohol (used as rubbing alcohol)}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input7" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Sodium bicarbonate (baking soda)}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="solid" value="solid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="liquid" value="liquid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input8" :disabled="!allowEditing">
                <v-radio key="gas" value="gas" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question180',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
    };
  },
};
</script>
